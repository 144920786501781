// @flow
import styled, { css } from "styled-components";
import { Button } from "@nested/component-library";
import {
  mediumHeading,
  extraLargeHeading,
  largeParagraph,
  extraLargeParagraph,
  media,
} from "@nested/brand";
import { getImage, scrollToById } from "@nested/utils";

const wrapperStyle = css`
  margin-bottom: 40px;
  background-color: ${({ theme }) => theme.palette.pink40};
  text-align: center;
`;

const Header = styled.h1`
  ${mediumHeading}
  padding: 40px 20px 0;
  margin: auto;
  max-width: 335px;
  ${media.tablet`
    ${extraLargeHeading}
    padding-top: 100px;
    max-width: 537px;
  `}
`;

const Text = styled.p`
  ${largeParagraph}
  padding-top: 20px;
  max-width: 233px;
  margin: auto;
  ${media.tablet`
    ${extraLargeParagraph}
    padding-top: 30px;
    max-width: 360px;
  `}
`;

const ButtonSection = styled.div`
  padding-top: 30px;
  display: inline-grid;
  justify-items: center;
  ${media.tablet`
    padding-top: 50px;
  `}
`;

const buttonStyle = css`
  border-radius: 10px;
  font-size: 20px;
  padding: 0 26px;
  margin-bottom: 20px;
`;

const buttonShadowStyle = css`
  display: none;
  ${media.tablet`
    display: block;
  `}
`;

/* The aim for tablet view is to get the houses on either side of the slice 
to always be a min distance apart, and to slide off the screen left and right
if the viewport cannot contain them. Essentially this is like having a 
centralized image behind the text that does not change size in relation to 
the viewport. To do this, I have:

* Set the width of the image container to be the maxDesktop breakpoint (1180px),
wide enough to contain the inner text (360px), the two houses (330px and 360px)
and the padding either side (52px) - total 1154px.

* Set the houses relative to the div so that they are 52px away from the left and 
right.

* Calculate the margin so that the image div appears centralized:

            |   viewport   |
____DIV_____|______________|_____________
|           |       |      |            |
|   left    |    center    |   right    |
|  margin   |      of      |   margin   |
|           |     page     |            |
|_house_____|_______|______|______house_|

So the *negative* left margin = half width of div - half of viewport

Therefore:
left margin = half of viewport - half width of div
 */
const imagesStyle = css`
  pointer-events: none;
  ${media.tablet`
    width: ${({ theme }) => `${theme.breakpoints.maxDesktop}px`};
    margin-top: -350px;
    margin-left: ${({ theme }) =>
      `calc(50vw - ${theme.breakpoints.maxDesktop / 2}px)`};
    position: relative;
    height: 290px;
  `}
  ${media.maxDesktop`
    width: 100%;
    margin-left: 0;
  `}
`;

const sharedHouseStyles = css`
  display: inline;
  position: absolute;
  height: auto;
`;

const leftHouseStyle = css`
  width: calc(100% - 40px);
  position: relative;
  top: 15vw;
  margin-top: -10vw;
  ${media.tablet`
    ${sharedHouseStyles};
    margin-top: 0;
    width: 330px;
    left: 52px;
    top: 80px;
  `}
`;

const rightHouseStyle = css`
  display: none;
  ${media.tablet`
    ${sharedHouseStyles}
    width: 360px;
    right: 52px;
    top: 10px;
  `}
`;

const leftHouseImageUrl = getImage("marketing/tech-careers/house_left.png");
const rightHouseImageUrl = getImage("marketing/tech-careers/house_right.png");
const buttonShadowUrl = getImage("marketing/tech-careers/button_shadow.svg");

type Props = {
  title: string,
  text: string,
  ctaText: string,
};

export const TechCareersHero = ({ title, text, ctaText }: Props) => (
  <div css={wrapperStyle}>
    <Header>{title}</Header>
    <Text>{text}</Text>
    <ButtonSection>
      <Button
        css={buttonStyle}
        size="large"
        onClick={() => scrollToById("current-jobs-list")}
        type="primary"
      >
        {ctaText}
      </Button>
      <img alt="Button shadow" css={buttonShadowStyle} src={buttonShadowUrl} />
    </ButtonSection>
    <div css={imagesStyle}>
      <img alt="Left house" css={leftHouseStyle} src={leftHouseImageUrl} />
      <img alt="Right house" css={rightHouseStyle} src={rightHouseImageUrl} />
    </div>
  </div>
);
