import Scroll from "react-scroll";

const getAbsolutePosition = (element) => {
  if (!element) return 0;
  const bodyRect = document.body.getBoundingClientRect();
  const elementRect = element.getBoundingClientRect();
  return elementRect.top - bodyRect.top;
};

export const scrollToById = (id, offset = 0) => {
  const destination = getAbsolutePosition(document.getElementById(id));
  return Scroll.animateScroll.scrollTo(destination - offset, { duration: 500 });
};
